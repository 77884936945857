window.Main = new (function () {

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        $('.wrap-popUp .close').on('click', function () {
            $(this).parent().addClass('d-none');
        });

        $('.iz-banners').slick({
            dots: true,
            arrows: false,
            speed: 300,
            slidesToShow: 1
        });

        $('.wrap-movies .wrap-titles label').on('click', that.changeSliderMovie);

        $('.wrap-slider').slick({
            dots: true,
            arrows: false,
            speed: 300,
            slidesToShow: 1,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        infinite: true,
                    }
                }
            ]
        });

        setTimeout(function (){
            $('.wrap-slider.documentales, .wrap-slider.kids').removeClass('active');
        }, 2000);

        $('.poster').on('click', that.playVideoiFrame);
    };

    this.changeSliderMovie = function () {
        let categoryMovie = $(this).data('slider');
        $('.wrap-movies .wrap-titles label').removeClass('active');
        $(this).addClass('active');
        $('.wrap-slider').removeClass('active');
        $('.wrap-slider.'+categoryMovie).addClass('active');
    };

    this.playVideoiFrame = function () {
          let iframeVideo = $(this).next();
          $(this).remove();
          $(iframeVideo)[0].src += "&autoplay=1";
    };

})();
